<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="products.length > 0"
		class="row">
		<div
			v-for="(product, i) of products"
			:key="i"
			:style="bgStyle(product)"
			:class="{
				'col-xl-6': products.length === 2,
				'reversed-bg': reversedBackground,
			}"
			class="col-12 p-3 store-product-banner">
			<div class="row container mx-auto p-0 h-100">
				<div
					:style="['md', 'lg', 'xl'].includes(windowWidth) && products.length === 1 ? 'max-width: 480px;' : ''"
					:class="{
						'ml-auto mr-0': i === 0 || (i === 1 && !['xl'].includes(windowWidth)),
						'pl-4': i === 1 && ['xl'].includes(windowWidth),
					}"
					class="col-12 col-md-6 py-3 d-flex flex-column">
					<div class="flex-grow-1">
						<h3
							:style="`color: ${bannerContent(product, 'titleColor')}`">
							{{ translate(product.code_name) }}
						</h3>
						<p
							class="text-justify mt-4"
							:style="`color: ${bannerContent(product, 'descriptionColor')}`"
							v-html="bannerContent(product, 'description')" />
						<div
							v-if="showPrice[i]"
							class="mb-0">
							<span
								class="h4 font-weight-bold"
								:style="`color: ${bannerContent(product, 'titleColor')}`">
								{{ product.selectable === null ? (product.price_amount === 0 ? translate('free') : product.price) : comboPrice(i) }}
							</span>
							<br><small :style="`color: ${bannerContent(product, 'descriptionColor')}`">{{ translate(`${product.code_name}_presentation`) }}</small>
						</div>
					</div>
					<div
						v-if="!showAddToCart[i] && showDetailButton[i]"
						class="row mt-4">
						<div
							class="col align-self-center d-flex"
							style="gap: 1rem;">
							<button
								style="font-weight: 600;"
								class="btn p-2 pointer custom-btn custom-primary-btn"
								:style="buttonClass(product)"
								@click="$router.push({ name: 'PromotionDetails', params: { promoName: product.decoded_name } })">
								{{ translate('buy_now') }}
							</button>
							<a
								v-if="product.brochure_url"
								style="font-weight: 600;"
								class="custom-primary-btn btn btn-outline-light p-2 pointer custom-btn"
								target="_blank"
								:href="product.brochure_url">
								{{ translate('see_brochure') }}
							</a>
						</div>
					</div>
					<div
						v-if="showAddToCart[i]"
						class="d-flex flex-wrap mt-4 justify-content-start">
						<div
							class="mb-4 align-self-center mt-1">
							<number-input-spinner
								v-model="product.quantity"
								:mouse-down-speed="500"
								:min="MIN_QTY"
								:max="MAX_QTY"
								:button-class="'vnis__button bg-primary-alt'"
								:input-class="'vnis__input vnis-input-custom-width'"
								:integer-only="true"
								class="vnis-custom-dimensions vnis-smaller justify-content-center" />
						</div>
						<div class="align-self-start">
							<div
								:class="`${buttonClass(product)} ${addProductsLoading[i] ? 'disabled' : ''}`"
								style="font-weight: 600;"
								class="btn p-2 pointer custom-btn custom-primary-btn"
								@click="$emit('addToCart', { [product.sku]: product.quantity })">
								<template v-if="addProductsLoading[i]">
									<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
								</template>
								<template v-else>
									{{ translate('add_to_cart') }}
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-5 order-first order-sm-last">
					<lazy>
						<img
							:src="product.image"
							:alt="product.decoded_name"
							class="img-fluid mx-auto"
							style="max-width:200px;">
					</lazy>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import { S3_PATH } from '@/settings/Images';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { Products, Store } from '@/translations';
import Lazy from '@/components/Lazy';

export default {
	name: 'StorePromotionBanner',
	components: { NumberInputSpinner, Lazy },
	mixins: [WindowSizes],
	messages: [Products, Store],
	props: {
		productsImport: {
			type: Array,
			default: () => [],
			// Currently the banner only supports 1 or 2 products
			validator: (value) => value.length <= 2,
		},
		showPrice: {
			type: Array,
			default: () => [true, true],
			validator: (value) => value.length <= 2 && value.filter((val) => typeof val !== 'boolean').length === 0,
		},
		addProductsLoading: {
			type: Array,
			default: () => [false, false],
			validator: (value) => value.length <= 2 && value.filter((val) => typeof val !== 'boolean').length === 0,
		},
		showAddToCart: {
			type: Array,
			default: () => [false, false],
			validator: (value) => value.length <= 2 && value.filter((val) => typeof val !== 'boolean').length === 0,
		},
		showDetailButton: {
			type: Array,
			default: () => [true, true],
			validator: (value) => value.length <= 2 && value.filter((val) => typeof val !== 'boolean').length === 0,
		},
		productsReference: {
			type: Array,
			default: () => [],
		},
		backgroundStyle: {
			type: String,
			default: '',
		},
		reversedBackground: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			products: this.productsImport.filter((p) => p),
			S3_PATH,
			MIN_QTY,
			MAX_QTY,
			theme: process.env.VUE_APP_THEME,
		};
	},
	watch: {
		productsImport() {
			this.products = this.productsImport.filter((p) => p);
		},
	},
	methods: {
		comboPrice(index) {
			return this.rangePrice(index);
		},
		rangePrice(index) {
			const { variants, products, discount_amount: discount } = this.products[index].selectable;
			const prices = variants
				.filter((variant) => variant.combination.every((combo) => this.productsReference.some((item) => item.decoded_name === combo)))
				.filter((variant) => variant.combination.filter((combo) => !(products[this.country] ?? products.default).includes(combo)).length === 0)
				.map((variant) => variant.combination.reduce((amount, _, i, categories) => amount + (this.productsReference.find((item) => item.decoded_name === categories[i])?.priceAmount ?? 0), 0) - discount)
				.filter((value, i, values) => values.indexOf(value) === i)
				.sort((a, b) => a - b);
			return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')} USD` : this.products[index].price;
		},
		bgStyle(product) {
			let style = '';
			if (this.showBackground(product)) {
				style = `--store-product-banner-background:url(${this.bannerContent(product, 'backgroundImg')});`;
			}
			return `${style} ${this.backgroundStyle}`;
		},
		bannerContent(product, data) {
			try {
				return product.bannerContent[data];
			} catch (e) {
				return '';
			}
		},
		showBackground(product) {
			if (typeof product.bannerContent.showBackground === 'undefined') {
				return true;
			}
			return product.bannerContent.showBackground;
		},
		buttonClass(product) {
			return `color: ${this.bannerContent(product, 'buttonTextColor')}; background-color: ${this.bannerContent(product, 'buttonBackgroundColor')}`;
		},
	},
};
</script>

<style scoped>
.store-product-banner:not(.reversed-bg) {
	/*background: var(--store-product-banner-background);
	background-position: 50% 100%;
	background-size:cover;
	background-repeat:no-repeat;*/
	background: rgb(115,36,129);
	background: linear-gradient(90deg, rgba(115,36,129,1) 0%, rgba(215,42,167,1) 50%, rgba(115,36,129,1) 100%);
}

.store-product-banner.reversed-bg::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: var(--store-product-banner-background);
	background-position: 50% 100%;
	background-size:cover;
	background-repeat:no-repeat;
	transform: scaleX(-1);
	-webkit-transform: scaleX(-1); /* Para soporte de navegadores antiguos */
}
</style>
